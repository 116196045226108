<template>
  <base-detail @closePage="closePage">
    <!-- 销售基本信息 -->
    <detail-list :formData="tableRow" :config="config">
      <!-- 销售单号 -->
      <template v-slot:sn="{ data }">
        {{ data.sale.sn }}
      </template>
      <!-- 客户信息 -->
      <template v-slot:customer="{ data }">
        {{ data.sale.customer.name }}
      </template>
      <!-- 出厂日期 -->
      <template v-slot:manufactureTime="{ data }">
        {{ $util.format(data.sale.manufactureTime, 'yyyy-MM-dd') }}
      </template>
      <!--  质保日期  -->
      <template v-slot:warrantyTime="{ data }">
        {{ $util.format(data.sale.warrantyTime, 'yyyy-MM-dd') }}
      </template>
    </detail-list>
    <!-- 设备列表(包含备件和故障)-->
    <h1 style="margin-top: 10px; position: relative">
      {{ $t('saleManage.equipment') }}
      <el-button
        size="mini"
        style="position: absolute; right: 0; top: -5px"
        @click="multipleExport"
      >
        <i class="iconfont el-icon-download" />
        {{ $t('functionEnum.AR_DIGTAL_EXPORT') }}
      </el-button>
    </h1>
    <table-list
      class="saleExportList"
      style="margin-top: 10px"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.saleEquipmentGetById"
      :defaultParams="defaultParams"
      :columns="columns"
      :pagination="false"
      :multiple="true"
      :treeProps="{ children: 'children' }"
      :highlightCurrentRow="false"
      v-model="subTableRows"
    >
      <!-- 类型 -->
      <template v-slot:level="{ row }">
        {{ levelNames[row.level] }}
      </template>
      <!-- 导出 -->
      <template v-slot:option="{ row }">
        <el-button type="text" @click="signleExport(row)">
          {{ $t('base.export') }}
        </el-button>
      </template>
    </table-list>
  </base-detail>
</template>

<script>
import { importFile } from '@/utils/ajax'
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      // 客户信息
      config: [
        // lang: 销售单号
        {
          label: this.$t('saleManage.sn'),
          prop: 'sn',
          span: 12,
        },
        // lang: 客户信息
        {
          label: this.$t('saleManage.customer'),
          prop: 'customer',
          span: 12,
        },
        // lang: 出厂日期
        {
          label: this.$t('saleManage.manufactureTime'),
          prop: 'manufactureTime',
          span: 12,
        },
        // lang: 质保周期
        {
          label: this.$t('saleManage.warrantyTime'),
          prop: 'warrantyTime',
          span: 12,
        },
      ],
      // 设备信息
      levelNames: {
        ONE: this.$t('equipmentManage.equipmentInfo'),
        TWO: this.$t('equipmentManage.partInfo'),
        THREE: this.$t('equipmentManage.faultInfo'),
      },
      columns: [
        // lang:类型
        {
          label: this.$t('equipmentManage.type'),
          prop: 'level',
          width: '140px',
        },
        // lang:名称
        {
          label: this.$t('equipmentManage.name'),
          prop: 'name',
          callback(row) {
            return row.equipment.name
          },
        },
        // lang:编号
        {
          label: this.$t('equipmentManage.number'),
          prop: 'number',
          callback(row) {
            return row.equipment.number
          },
        },
        // lang:型号
        {
          label: this.$t('equipmentManage.model'),
          prop: 'model',
          callback(row) {
            return row.equipment.model
          },
        },
        // lang:规格
        {
          label: this.$t('equipmentManage.specifications'),
          prop: 'specifications',
          callback(row) {
            return row.equipment.specifications
          },
        },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
        },
      ],
      subTableRows: [],
      defaultParams: {
        id: this.tableRow.id,
      },
    }
  },

  methods: {
    // 关闭页面按钮点击回调
    closePage() {
      this.$emit('closePage')
    },
    // 多个导出
    multipleExport() {
      if (!this.subTableRows.length) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      const ids = this.subTableRows.map((item) => item.id)
      this.exportMethod(ids.join(','))
    },
    // 单个导出
    signleExport(row) {
      this.exportMethod(row.id)
    },
    // 导出二维码
    exportMethod(ids) {
      importFile({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.saleExportSaleCode,
        // lang:销售二维码
        name: this.$t('saleManage.saleCode'),
        type: '.docx',
        data: {
          qrcodeSize: 170,
          logoSize: 10,
          fontWidth: 12,
          clo: 3,
          pageCount: 3,
          saleDetailId: ids,
        },
      })
    },
  },
}
</script>